// import { Modal } from 'bootstrap';
// import Modal from 'bootstrap/js/dist/modal';
// import Modal from 'bootstrap/js/dist/modal';
// import Tooltip from 'bootstrap/js/dist/tooltip';
import { Tooltip, Modal } from 'bootstrap';
// import('bootstrap/js/dist/modal') Modal from 'bootstrap/js/dist/modal';

// Enabling bootstrap tooltip
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-tooltip="show"]'))
// var tooltipTriggerList = [].slice.call(document.querySelectorAll('.hero .nav-link'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl)
})
